import { useFormContext } from "react-hook-form";

import { FormTextField } from "../../base/Form/FormTextField";

export function HeatPumpSettings() {
  const { register } = useFormContext();

  return (
    <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
      <p className="text-base mb-2 font-medium">Heat Pump Settings</p>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <FormTextField
          {...register("heatPumpSettings.assumedFlowRate", {
            min: {
              value: 0,
              message: "Assumed Flow Rate (L/min) cannot be less than 0",
            },
            max: {
              value: 500,
              message: "Assumed Flow Rate (L/min) cannot be greater than 500",
            },
            validate: {
              isNumber: (value: any) => !isNaN(value),
            },
          })}
          label="Assumed Flow Rate (L/min)"
        />
        <FormTextField
          {...register("heatPumpSettings.antifreeze", {
            min: { value: 0, message: "Antifreeze (%) cannot be less than 0" },
            max: {
              value: 100,
              message: "Antifreeze (%) cannot be greater than 100",
            },
            validate: {
              isNumber: (value: any) => !isNaN(value),
            },
          })}
          label="Antifreeze (%)"
        />
      </div>
    </div>
  );
}

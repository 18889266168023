import { useFormContext } from "react-hook-form";

import { Button } from "../../base/Button";
import { ComponentCalibration } from "../ComponentCalibration";
import { IHardwareCreateUpdateFormFields } from "../../../types/Hardware/Hardware";
import { calibrationDefaults } from "../../../constants/peripheralCalibrationDefaults";
import {
  isValidScaleValue,
  isValidVMOffsetValue,
} from "../../../utils/validationFunctions";

export function VoltageMeterSettings() {
  const { watch, register, setValue } =
    useFormContext<IHardwareCreateUpdateFormFields>();

  const handleApplyDefaultVMCalib = () => {
    setValue("peripheralSettings.voltmeter", {
      ...calibrationDefaults.voltmeter,
    });
  };

  return (
    <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
      <div className="flex items-center justify-between mb-4">
        <p className="text-base font-medium">Voltage Meter Settings</p>
        {watch("peripheralSettings.voltmeter.enabled") && (
          <Button onClick={() => handleApplyDefaultVMCalib()} size="sm">
            Apply Default Calibration
          </Button>
        )}
      </div>
      <ComponentCalibration
        offsetLabel="Offset (V)"
        offsetRegisterFn={register("peripheralSettings.voltmeter.offset", {
          validate: {
            isValid: (v) =>
              isValidVMOffsetValue(v)
                ? true
                : "Offset must be between -3276.8V and 3276.7V",
          },
        })}
        scaleRegisterFn={register("peripheralSettings.voltmeter.scale", {
          validate: {
            isValid: (v) =>
              isValidScaleValue(v)
                ? true
                : "Scale must be between 0.1% and 6553.5%",
          },
        })}
        classes="grid grid-cols-2 sm:grid-cols-4 gap-4"
      />
    </div>
  );
}

import { useFormContext } from "react-hook-form";

import { FormSelect } from "../../base/Form/FormSelect";
import { FormTextField } from "../../base/Form/FormTextField";
import { IHardwareCreateUpdateFormFields } from "../../../types/Hardware/Hardware";

export function ModbusSettings() {
  const protocolOptions = ["RTU", "TCP/IP"];
  const { register, watch } = useFormContext<IHardwareCreateUpdateFormFields>();

  return (
    <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
      <p className="text-base mb-2 font-medium">Modbus Settings</p>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <FormSelect {...register("modbusSettings.protocol")} label="Protocol">
          <option disabled hidden value={0}>
            Please Select
          </option>
          {protocolOptions.map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </FormSelect>
        {watch("modbusSettings.protocol") === "RTU" ? (
          <FormTextField
            {...register("modbusSettings.rtuAddress", {
              min: { value: 0, message: "Address cannot be less than 0" },
              max: {
                value: 255,
                message: "Address cannot be greater than 255",
              },
            })}
            label="Address"
          />
        ) : (
          <>
            <FormTextField
              {...register("modbusSettings.ipAddress", {
                pattern: {
                  value:
                    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                  message: "IP Address is not valid",
                },
              })}
              label="IP Address"
            />
            <FormTextField
              {...register("modbusSettings.port", {
                min: { value: 0, message: "Port cannot be less than 0" },
                max: {
                  value: 65535,
                  message: "Port cannot be greater than 65535",
                },
              })}
              label="Port"
            />
          </>
        )}
      </div>
    </div>
  );
}

export const calibrationDefaults: Record<string, any> = {
  voltmeter: {
    offset: "0.0",
    scale: "95.3",
  },
  ctClamp: {
    offset: "-0.12",
    scale: "93.8",
  },
  temperatureSensor: {
    offset: "-2.40",
    scale: "100.0",
  }
}
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import clsx from "clsx";
import date from "date-and-time";

import { Alert } from "../base/Alert";
import { pdOrgId } from "../../config";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";
import { Select } from "../base/Select";
import { Form } from "../base/Form/Form";
import { Modal } from "../../layouts/Modal";
import { IDevice } from "../../types/Device/Device";
import { FormModelSelect } from "./FormModelSelect";
import { FormSelect } from "../base/Form/FormSelect";
import { FormToggle } from "../base/Form/FormToggle";
import useDevices from "../../data/device/useDevices";
import { SectionLoading } from "../shared/SectionLoading";
import useHardware from "../../data/hardware/useHardware";
import useUsedSeats from "../../data/billing/useUsedSeats";
import { FormTextField } from "../base/Form/FormTextField";
import { useUserContext } from "../../contexts/UserContext";
import useSubscription from "../../data/billing/useSubscription";
import { FormManufacturerSelect } from "./FormManufacturerSelect";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { SerialSettings } from "./CreateEditHardware/SerialSettings";
import { HeatPumpSettings } from "./CreateEditHardware/HeatPumpSettings";
import useHardwareMutations from "../../data/hardware/useHardwareMutations";
import { ConfirmDefaultSerialSettings } from "./ConfirmDefaultSerialSettings";
import { ConfirmSubscriptionUpdate } from "../shared/ConfirmSubscriptionChangeModal";
import { VoltageMeterSettings } from "./CreateEditHardware/VoltageMeterSettings";
import {
  HardwareModbusProtocol,
  HardwareTemperatureProbeLocation,
  IHardware,
  IHardwareCreateUpdateFormFields,
} from "../../types/Hardware/Hardware";
import { CurrentTransformerSettings } from "./CreateEditHardware/CurrentTransformerSettings";
import { TemperatureSensorSettings } from "./CreateEditHardware/TemperatureSensorSettings";
import { ModbusSettings } from "./CreateEditHardware/ModbusSettings";
import { calibrationDefaults } from "../../constants/peripheralCalibrationDefaults";
import { PowerSettings } from "./CreateEditHardware/PowerSettings";
import { hardwareSettingsFormatters } from "../../utils/hardwareSettingsFormatters";

export interface CreateEditHardwareProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  hardware?: IHardware;
}

export function CreateEditHardware({
  open,
  onClose,
  hardware,
}: CreateEditHardwareProps) {
  const { partnerId } = useUserContext();
  const { hardware: allHardware } = useHardware();
  const { devices: allDevices } = useDevices();
  const { createHardware, updateHardware } = useHardwareMutations();
  const { hardwareTypes } = useHardwareTypes();
  const { subscription } = useSubscription();
  const { usedSeats } = useUsedSeats();
  const [subscriptionDisabledError, setSubscriptionDisabledError] =
    useState(false);
  const [devices, setDevices] = useState<IDevice[] | undefined>(undefined);
  const [defaultSerial, setDefaultSerial] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const [subscriptionTier, setSubscriptionTier] = useState<number>(1);
  const [chosenTier, setChosenTier] = useState<number | null>(null);
  const [subscriptionUpdateOpen, setSubscriptionUpdateOpen] = useState(false);
  const [confirmDefaultSerialOpen, setConfirmDefaultSerialOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    onClose(false);
    usedSeats.refetch();
    subscription.refetch();
    setTimeout(() => {
      setDefaultSerial(true);
      setSubscriptionId(null);
      setSubscriptionTier(1);
      setChosenTier(null);
    }, 200);
  };

  const handleCreate = (data: IHardwareCreateUpdateFormFields) => {
    createHardware.mutate({
      ...data,
      deviceId: data.deviceId !== "0" ? data.deviceId : undefined,
      installedDate: data.installedDate !== "" ? data.installedDate : undefined,
      serviceDue: data.serviceDue !== "" ? data.serviceDue : undefined,
      hardwareModelId: data.hardwareModelId,
      hardwareManufacturerId: data.hardwareManufacturerId,
      hardwareTypeId: data.hardwareTypeId,
      useDefaultSerialSettings: defaultSerial,
      subscriptionId: subscriptionId ? subscriptionId : undefined,
      tier: subscriptionTier ? subscriptionTier : 1,
      modbusSettings: {
        protocol:
          data.modbusSettings.protocol === "RTU"
            ? HardwareModbusProtocol.RTU
            : HardwareModbusProtocol.TCPIP,
        rtuAddress: data.modbusSettings.rtuAddress
          ? parseInt(data.modbusSettings.rtuAddress)
          : undefined,
        ipAddress:
          data.modbusSettings.protocol === "RTU"
            ? undefined
            : data.modbusSettings.ipAddress,
        port:
          data.modbusSettings.protocol === "RTU"
            ? undefined
            : parseInt(data.modbusSettings.port ?? "502"),
      },
      serialSettings: defaultSerial
        ? undefined
        : {
            baudRate: parseInt(data.serialSettings.baudRate),
            dataBits: parseInt(data.serialSettings.dataBits),
            parity: parseInt(data.serialSettings.parity),
            stopBits: parseInt(data.serialSettings.stopBits),
          },
      heatPumpSettings: {
        assumedFlowRate: data.heatPumpSettings.assumedFlowRate
          ? hardwareSettingsFormatters.heatPumpSettings.flowRate.toInteger(
              data.heatPumpSettings.assumedFlowRate
            )
          : 0,
        antifreeze: data.heatPumpSettings.antifreeze
          ? parseFloat(data.heatPumpSettings.antifreeze)
          : 0,
      },
      powerSettings: {
        measuredVoltage: parseInt(data.powerSettings.measuredVoltage),
        frequency: parseInt(data.powerSettings.frequency),
        phase: parseInt(data.powerSettings.phase),
      },
      peripheralSettings: {
        ctClamp: {
          enabled: data.peripheralSettings.ctClamp.enabled,
          current: parseInt(data.peripheralSettings.ctClamp.current),
          resolution: parseInt(data.peripheralSettings.ctClamp.resolution),
          scale: hardwareSettingsFormatters.ctClampSettings.scale.toInteger(
            data.peripheralSettings.ctClamp.scale
          ),
          offset: hardwareSettingsFormatters.ctClampSettings.offset.toInteger(
            data.peripheralSettings.ctClamp.offset
          ),
        },
        voltmeter: {
          enabled: data.peripheralSettings.ctClamp.enabled,
          scale: hardwareSettingsFormatters.voltMeterSettings.scale.toInteger(
            data.peripheralSettings.voltmeter.scale
          ),
          offset: hardwareSettingsFormatters.voltMeterSettings.offset.toInteger(
            data.peripheralSettings.voltmeter.offset
          ),
        },
        temperatureSensor: {
          enabled: data.peripheralSettings.temperatureSensor.enabled,
          location:
            data.peripheralSettings.temperatureSensor.location === "Indoor"
              ? HardwareTemperatureProbeLocation.Indoor
              : HardwareTemperatureProbeLocation.Outdoor,
          scale: hardwareSettingsFormatters.tempSensorSettings.scale.toInteger(
            data.peripheralSettings.temperatureSensor.scale
          ),
          offset:
            hardwareSettingsFormatters.tempSensorSettings.offset.toInteger(
              data.peripheralSettings.temperatureSensor.offset
            ),
        },
      },
    });
    handleClose();
  };

  const handleUpdate = (data: IHardwareCreateUpdateFormFields) => {
    updateHardware.mutate({
      ...hardware,
      ...data,
      deviceId: data.deviceId !== "0" ? data.deviceId : undefined,
      hardwareModelId: data.hardwareModelId,
      hardwareManufacturerId: data.hardwareManufacturerId,
      hardwareTypeId: data.hardwareTypeId,
      subscriptionId: subscriptionId ? subscriptionId : undefined,
      tier: subscriptionTier!,
      modbusSettings: {
        protocol:
          data.modbusSettings.protocol === "RTU"
            ? HardwareModbusProtocol.RTU
            : HardwareModbusProtocol.TCPIP,
        rtuAddress: data.modbusSettings.rtuAddress
          ? parseInt(data.modbusSettings.rtuAddress)
          : 1,
        ipAddress:
          data.modbusSettings.protocol === "RTU"
            ? undefined
            : data.modbusSettings.ipAddress,
        port:
          data.modbusSettings.protocol === "RTU"
            ? undefined
            : parseInt(data.modbusSettings.port ?? "502"),
      },
      serialSettings: {
        baudRate: parseInt(data.serialSettings.baudRate),
        dataBits: parseInt(data.serialSettings.dataBits),
        parity: parseInt(data.serialSettings.parity),
        stopBits: parseInt(data.serialSettings.stopBits),
      },
      heatPumpSettings: {
        assumedFlowRate:
          hardwareSettingsFormatters.heatPumpSettings.flowRate.toInteger(
            data.heatPumpSettings.assumedFlowRate ?? "0"
          ),
        antifreeze: parseFloat(data.heatPumpSettings.antifreeze!),
      },
      powerSettings: {
        measuredVoltage: parseInt(data.powerSettings.measuredVoltage),
        frequency: parseInt(data.powerSettings.frequency),
        phase: parseInt(data.powerSettings.phase),
      },
      peripheralSettings: {
        ctClamp: {
          enabled: data.peripheralSettings.ctClamp.enabled,
          current: parseInt(data.peripheralSettings.ctClamp.current),
          resolution: parseInt(data.peripheralSettings.ctClamp.resolution),
          scale: hardwareSettingsFormatters.ctClampSettings.scale.toInteger(
            data.peripheralSettings.ctClamp.scale
          ),
          offset: hardwareSettingsFormatters.ctClampSettings.offset.toInteger(
            data.peripheralSettings.ctClamp.offset
          ),
        },
        voltmeter: {
          enabled: data.peripheralSettings.voltmeter.enabled,
          scale: hardwareSettingsFormatters.voltMeterSettings.scale.toInteger(
            data.peripheralSettings.voltmeter.scale
          ),
          offset: hardwareSettingsFormatters.voltMeterSettings.scale.toInteger(
            data.peripheralSettings.voltmeter.offset
          ),
        },
        temperatureSensor: {
          enabled: data.peripheralSettings.temperatureSensor.enabled,
          location:
            data.peripheralSettings.temperatureSensor.location === "Indoor"
              ? HardwareTemperatureProbeLocation.Indoor
              : HardwareTemperatureProbeLocation.Outdoor,
          scale: hardwareSettingsFormatters.tempSensorSettings.scale.toInteger(
            data.peripheralSettings.temperatureSensor.scale
          ),
          offset:
            hardwareSettingsFormatters.tempSensorSettings.offset.toInteger(
              data.peripheralSettings.temperatureSensor.offset
            ),
        },
      },
    });
    handleClose();
  };

  const handleDefaultSerialToggle = (checked: boolean) => {
    if (!checked) {
      setConfirmDefaultSerialOpen(true);
    } else {
      setDefaultSerial(checked);
    }
  };

  const handleSeatChange = (e: any) => {
    let chosenTier = parseInt(e.target.value);
    let _usedSeats = 0;
    let _subSeats = 0;
    let requireCheck = true;

    switch (chosenTier) {
      case 2:
        _usedSeats = usedSeats.data![0].standard;
        _subSeats = subscription.data![0].seats.standard;
        break;
      case 1:
      default:
        setSubscriptionId(null);
        requireCheck = false;
        break;
    }

    if (requireCheck) {
      if (_usedSeats === _subSeats || _usedSeats > _subSeats) {
        setSubscriptionUpdateOpen(true);
        setChosenTier(chosenTier);
      } else {
        setSubscriptionId(subscription.data![0].id);
        setSubscriptionTier(chosenTier);
      }
    } else {
      setSubscriptionId(null);
      setSubscriptionTier(chosenTier);
    }
  };

  const handleSubscriptionUpdateCancel = () => {
    setSubscriptionUpdateOpen(false);
    setSubscriptionId(null);
    setSubscriptionTier(hardware?.tier ? hardware.tier : 1);
  };

  const handleSubscriptionUpdateConfirm = () => {
    setSubscriptionUpdateOpen(false);
    setSubscriptionId(subscription.data![0].id);
    setSubscriptionTier(chosenTier!);
  };

  useEffect(() => {
    if (hardware) {
      setDefaultSerial(true);
      setSubscriptionId(
        hardware.subscriptionId ? hardware.subscriptionId : null
      );
      setSubscriptionTier(hardware.tier);
    } else {
      setDefaultSerial(true);
      setSubscriptionId(null);
      setSubscriptionTier(1);
    }
  }, [hardware]);

  useEffect(() => {
    if (
      !allDevices.isLoading &&
      !allHardware.isLoading &&
      allDevices.data &&
      allHardware.data
    ) {
      let currentDevice;

      let filteredDevices = allDevices.data.filter(
        (device) =>
          !allHardware.data.some((hardware) => hardware.deviceId === device.id)
      );

      if (hardware) {
        currentDevice = allDevices.data.find(
          (device) => device.id === hardware?.deviceId
        );
        currentDevice && filteredDevices.push(currentDevice);
      }

      setDevices(filteredDevices);
      setLoading(false);
    }
  }, [
    hardware,
    allDevices.isLoading,
    allHardware.isLoading,
    allDevices.data,
    allHardware.data,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${hardware ? "Manage" : "Install"} Device`}
      description={`${hardware ? "Manage" : "Install"} an Atmo device.`}
    >
      {hardwareTypes.isLoading ||
      allHardware.isLoading ||
      subscription.isLoading ||
      usedSeats.isLoading ||
      loading ? (
        <SectionLoading />
      ) : !subscription.isError && !allHardware.isError ? (
        <>
          {subscription.data !== undefined &&
            subscription.data[0] !== undefined && (
              <ConfirmSubscriptionUpdate
                tier="standard"
                currentSeats={subscription.data![0].seats.standard}
                open={subscriptionUpdateOpen}
                onCancel={handleSubscriptionUpdateCancel}
                onConfirm={handleSubscriptionUpdateConfirm}
              />
            )}
          <ConfirmDefaultSerialSettings
            open={confirmDefaultSerialOpen}
            onCancel={() => setConfirmDefaultSerialOpen(false)}
            onConfirm={() => {
              setDefaultSerial(false);
              setConfirmDefaultSerialOpen(false);
            }}
          />
          <Form<IHardwareCreateUpdateFormFields>
            defaultValues={
              hardware
                ? {
                    name: hardware.name,
                    reference: hardware.reference,
                    installedDate:
                      hardware.serviceDue &&
                      date.format(
                        new Date(hardware.installedDate),
                        "YYYY-MM-DD"
                      ),
                    serviceDue:
                      hardware.serviceDue &&
                      date.format(new Date(hardware.serviceDue), "YYYY-MM-DD"),
                    deviceId: hardware.deviceId ? hardware.deviceId : "0",
                    hardwareModelId: hardware.hardwareModelId,
                    hardwareManufacturerId: hardware.hardwareManufacturerId,
                    hardwareTypeId: hardware.hardwareTypeId,
                    modbusSettings: {
                      protocol:
                        hardware.modbusSettings.protocol ===
                        HardwareModbusProtocol.RTU
                          ? "RTU"
                          : "TCP/IP",
                      rtuAddress: hardware.modbusSettings.rtuAddress
                        ? String(hardware.modbusSettings.rtuAddress)
                        : "1",
                      ipAddress: hardware.modbusSettings.ipAddress,
                      port: hardware.modbusSettings.port
                        ? String(hardware.modbusSettings.port)
                        : "502",
                    },
                    heatPumpSettings: {
                      assumedFlowRate: hardware.heatPumpSettings.assumedFlowRate
                        ? hardwareSettingsFormatters.heatPumpSettings.flowRate.toDisplay(
                            hardware.heatPumpSettings.assumedFlowRate
                          )
                        : "0.0",
                      antifreeze: hardware.heatPumpSettings.antifreeze
                        ? String(hardware.heatPumpSettings.antifreeze)
                        : "0",
                    },
                    serialSettings: {
                      baudRate: String(hardware.serialSettings.baudRate),
                      dataBits: String(hardware.serialSettings.dataBits),
                      parity: String(hardware.serialSettings.parity),
                      stopBits: String(hardware.serialSettings.stopBits),
                    },
                    powerSettings: {
                      measuredVoltage: String(
                        hardware.powerSettings.measuredVoltage
                      ),
                      frequency: String(hardware.powerSettings.frequency),
                      phase: String(hardware.powerSettings.phase),
                    },
                    peripheralSettings: {
                      ctClamp: {
                        current: String(
                          hardware.peripheralSettings.ctClamp?.current
                        ),
                        resolution: String(
                          hardware.peripheralSettings.ctClamp?.resolution
                        ),
                        enabled: hardware.peripheralSettings.ctClamp?.enabled,
                        offset: hardware.peripheralSettings.ctClamp?.offset
                          ? hardwareSettingsFormatters.ctClampSettings.offset.toDisplay(
                              hardware.peripheralSettings.ctClamp?.offset
                            )
                          : calibrationDefaults.ctClamp.offset,
                        scale: hardware.peripheralSettings.ctClamp?.scale
                          ? hardwareSettingsFormatters.ctClampSettings.scale.toDisplay(
                              hardware.peripheralSettings.ctClamp?.scale
                            )
                          : calibrationDefaults.ctClamp.scale,
                      },
                      voltmeter: {
                        enabled: hardware.peripheralSettings.voltmeter?.enabled,
                        offset: hardware.peripheralSettings.voltmeter?.offset
                          ? hardwareSettingsFormatters.voltMeterSettings.offset.toDisplay(
                              hardware.peripheralSettings.voltmeter?.offset
                            )
                          : calibrationDefaults.voltmeter.offset,
                        scale: hardware.peripheralSettings.voltmeter?.scale
                          ? hardwareSettingsFormatters.voltMeterSettings.scale.toDisplay(
                              hardware.peripheralSettings.voltmeter?.scale
                            )
                          : calibrationDefaults.voltmeter.scale,
                      },
                      temperatureSensor: {
                        location:
                          hardware.peripheralSettings.temperatureSensor
                            .location ===
                          HardwareTemperatureProbeLocation.Indoor
                            ? "Indoor"
                            : "Outdoor",
                        enabled:
                          hardware.peripheralSettings.temperatureSensor
                            ?.enabled,
                        offset: hardware.peripheralSettings.temperatureSensor
                          ?.offset
                          ? hardwareSettingsFormatters.tempSensorSettings.offset.toDisplay(
                              hardware.peripheralSettings.temperatureSensor
                                ?.offset
                            )
                          : calibrationDefaults.temperatureSensor.offset,
                        scale: hardware.peripheralSettings.temperatureSensor
                          ?.scale
                          ? hardwareSettingsFormatters.tempSensorSettings.scale.toDisplay(
                              hardware.peripheralSettings.temperatureSensor
                                ?.scale
                            )
                          : calibrationDefaults.temperatureSensor.scale,
                      },
                    },
                  }
                : {
                    installedDate: date.format(new Date(), "YYYY-MM-DD"),
                    serviceDue: date.format(
                      date.addYears(new Date(), 1),
                      "YYYY-MM-DD"
                    ),
                    modbusSettings: {
                      protocol: "RTU",
                      rtuAddress: "1",
                      ipAddress: "0.0.0.0",
                      port: "502",
                    },
                    heatPumpSettings: {
                      assumedFlowRate: "0.0",
                      antifreeze: "0",
                    },
                    serialSettings: {
                      baudRate: "0",
                      dataBits: "0",
                      parity: "0",
                      stopBits: "0",
                    },
                    powerSettings: {
                      measuredVoltage: "230",
                      frequency: "50",
                      phase: "1",
                    },
                    peripheralSettings: {
                      ctClamp: {
                        ...calibrationDefaults.ctClamp,
                        enabled: false,
                        resolution: "1000",
                        current: "30",
                      },
                      voltmeter: {
                        ...calibrationDefaults.voltmeter,
                        enabled: false,
                        frequency: "50",
                      },
                      temperatureSensor: {
                        enabled: false,
                        ...calibrationDefaults.temperatureSensor,
                        location: "Indoor",
                      },
                    },
                  }
            }
            onSubmit={hardware ? handleUpdate : handleCreate}
            className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
          >
            {({ register, watch, setValue, control }) => (
              <>
                <FormTextField
                  {...register("name", { required: true })}
                  label="Device Name"
                />
                <FormTextField
                  {...register("reference")}
                  label="Unique Reference/Identifier"
                />
                <FormTextField
                  {...register("installedDate", {
                    required: true,
                    onChange: (e) => {
                      setValue(
                        "serviceDue",
                        date.format(
                          date.addYears(
                            date.parse(e.target.value, "YYYY-MM-DD"),
                            1
                          ),
                          "YYYY-MM-DD"
                        )
                      );
                    },
                  })}
                  label="Heat Pump Installation Date"
                  type="date"
                  disabled={hardware !== undefined}
                />
                <FormTextField
                  {...register("serviceDue", {
                    validate: {
                      notLessThanStart: (serviceDue, formValues) =>
                        serviceDue > formValues.installedDate
                          ? true
                          : "Service Due must be ahead of install date",
                    },
                  })}
                  label="Heat Pump Service Due Date"
                  type="date"
                />
                <FormSelect
                  {...register("deviceId", {
                    required: true,
                  })}
                  label="Atmo Device"
                >
                  {devices!.map((device) => (
                    <option key={device.id} value={device.id}>
                      {device.id}
                    </option>
                  ))}
                  <option value="0">No Device</option>
                </FormSelect>
                <fieldset disabled={hardware !== undefined}>
                  <FormSelect
                    {...register("hardwareTypeId", {
                      required: true,
                    })}
                    label="Type of Renewable Technology"
                  >
                    <option disabled hidden value={undefined}>
                      Please Select
                    </option>
                    {hardwareTypes.data?.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </FormSelect>
                </fieldset>
                <fieldset
                  disabled={hardware !== undefined || !watch("hardwareTypeId")}
                >
                  <FormManufacturerSelect
                    {...register("hardwareManufacturerId", {
                      required: true,
                    })}
                    type={watch("hardwareTypeId")}
                  />
                </fieldset>
                <fieldset
                  disabled={
                    hardware !== undefined || !watch("hardwareManufacturerId")
                  }
                >
                  <FormModelSelect
                    {...register("hardwareModelId", {
                      required: true,
                    })}
                    manufacturer={watch("hardwareManufacturerId")}
                  />
                </fieldset>
                {subscription.data !== undefined &&
                subscription.data[0] !== undefined ? (
                  <div
                    className="relative"
                    onMouseEnter={
                      watch("deviceId") === "0"
                        ? () => setSubscriptionDisabledError(true)
                        : () => {}
                    }
                    onMouseLeave={
                      watch("deviceId") === "0"
                        ? () => setSubscriptionDisabledError(false)
                        : () => {}
                    }
                  >
                    <Select
                      value={subscriptionTier}
                      onChange={(e) => handleSeatChange(e)}
                      label="Subscription Tier"
                      disabled={watch("deviceId") === "0"}
                    >
                      <option value={1}>Free</option>
                      <option value={2}>Standard</option>
                    </Select>
                    <div
                      className={clsx(
                        subscriptionDisabledError ? "block " : "hidden ",
                        "absolute z-20 left-6 mt-2 top-16 px-3 py-2 rounded-md border border-red-500 bg-red-300/90 dark:border-red-700 dark:bg-red-600/90"
                      )}
                    >
                      You must choose an Atmo Device first
                    </div>
                  </div>
                ) : (
                  <>
                    <Select
                      disabled
                      onChange={(e) => handleSeatChange(e)}
                      label="Subscription Tier"
                      color="secondary"
                    >
                      <option value={1}>Free</option>
                      <option value={2}>Standard</option>
                    </Select>
                    {partnerId && partnerId !== pdOrgId ? (
                      <Alert
                        title="Installer managed billing"
                        message="You are unable to make any subscription changes, please contact your installer"
                      />
                    ) : (
                      <Alert
                        severity="error"
                        message="No subscription found, please see the billing section to resolve this"
                      />
                    )}
                  </>
                )}
                {watch("hardwareModelId") && (
                  <div
                    className={`flex flex-col space-y-4 items-start sm:space-y-0 sm:space-x-8 sm:my-2 sm:flex-row sm:col-span-2`}
                  >
                    <FormToggle<IHardwareCreateUpdateFormFields>
                      control={control}
                      name="peripheralSettings.ctClamp.enabled"
                      toggleProps={{
                        label: "Current Transformer",
                        labelRight: true,
                      }}
                    />

                    <FormToggle<IHardwareCreateUpdateFormFields>
                      control={control}
                      name="peripheralSettings.voltmeter.enabled"
                      toggleProps={{
                        label: "Voltage Meter",
                        labelRight: true,
                      }}
                    />

                    <FormToggle<IHardwareCreateUpdateFormFields>
                      control={control}
                      name="peripheralSettings.temperatureSensor.enabled"
                      toggleProps={{
                        label: "Temperature Sensor",
                        labelRight: true,
                      }}
                    />

                    {!hardware ? (
                      <Toggle
                        checked={defaultSerial}
                        onChange={(val) => handleDefaultSerialToggle(val)}
                        label="Default Serial Settings"
                        labelRight
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
                {watch("hardwareTypeId") ===
                  "aa6bfc6a-66a4-4113-af9a-1d0bdec5b8ae" && (
                  <HeatPumpSettings />
                )}
                {watch("hardwareModelId") && <PowerSettings />}
                {watch("peripheralSettings.ctClamp.enabled") && (
                  <CurrentTransformerSettings />
                )}
                {watch("peripheralSettings.voltmeter.enabled") && (
                  <VoltageMeterSettings />
                )}
                {watch("peripheralSettings.temperatureSensor.enabled") && (
                  <TemperatureSensorSettings />
                )}
                {(watch("hardwareModelId") || hardware) && <ModbusSettings />}
                {watch("hardwareModelId") && (!defaultSerial || hardware) && (
                  <SerialSettings />
                )}
                <div className="mt-6 sm:col-span-2 space-x-4">
                  <Button type="submit">
                    {hardware ? "Update" : "Create"}
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Form>
        </>
      ) : (
        <Alert
          severity="error"
          title="Error getting billing information"
          message="We were unable to retrieve billing information, please try again"
        />
      )}
    </Modal>
  );
}

const _toDisplay = (val: number, factor: number) =>
  (val / factor).toFixed(factor.toString().length - 1);

const _toInteger = (val: string, factor: number) => parseFloat(val) * factor;

export const hardwareSettingsFormatters = {
  heatPumpSettings: {
    flowRate: {
      toDisplay: (val: number) => String(val.toFixed(1)),
      toInteger: (val: string) => parseFloat(val),
    },
  },
  voltMeterSettings: {
    offset: {
      toDisplay: (val: number) => _toDisplay(val, 10),
      toInteger: (val: string) => _toInteger(val, 10),
    },
    scale: {
      toDisplay: (val: number) => _toDisplay(val, 10),
      toInteger: (val: string) => _toInteger(val, 10),
    },
  },
  ctClampSettings: {
    offset: {
      toDisplay: (val: number) => _toDisplay(val, 100),
      toInteger: (val: string) => _toInteger(val, 100),
    },
    scale: {
      toDisplay: (val: number) => _toDisplay(val, 10),
      toInteger: (val: string) => _toInteger(val, 10),
    },
  },
  tempSensorSettings: {
    offset: {
      toDisplay: (val: number) => _toDisplay(val, 100),
      toInteger: (val: string) => _toInteger(val, 100),
    },
    scale: {
      toDisplay: (val: number) => _toDisplay(val, 10),
      toInteger: (val: string) => _toInteger(val, 10),
    },
  },
};

import { useFormContext } from "react-hook-form";

import { Button } from "../../base/Button";
import { FormSelect } from "../../base/Form/FormSelect";
import { ComponentCalibration } from "../ComponentCalibration";
import {
  isValidCTOffsetValue,
  isValidScaleValue,
} from "../../../utils/validationFunctions";
import { calibrationDefaults } from "../../../constants/peripheralCalibrationDefaults";

export function CurrentTransformerSettings() {
  const currentOpts = [5, 10, 20, 30, 50, 80, 100];
  const resolutionOpts = [500, 750, 1000, 2000];
  const { register, setValue } = useFormContext();

  const handleApplyDefaultCTCalib = () => {
    setValue("peripheralSettings.ctClamp", {
      ...calibrationDefaults.ctClamp,
    });
  };

  return (
    <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
      <div className="flex items-center justify-between mb-4">
        <p className="text-base font-medium">Current Transformer Calibration</p>
        <Button onClick={() => handleApplyDefaultCTCalib()} size="sm">
          Apply Default Calibration
        </Button>
      </div>
      <ComponentCalibration
        offsetLabel="Offset (A)"
        offsetRegisterFn={register("peripheralSettings.ctClamp.offset", {
          validate: {
            isValid: (v) =>
              isValidCTOffsetValue(v)
                ? true
                : "Offset must be between -327.68A and 327.67A",
          },
        })}
        scaleRegisterFn={register("peripheralSettings.ctClamp.scale", {
          validate: {
            isValid: (v) =>
              isValidScaleValue(v)
                ? true
                : "Scale must be between 0.1% and 6553.5%",
          },
        })}
        classes="grid grid-cols-2 sm:grid-cols-4 gap-4"
        extraConfigComponent={
          <>
            <FormSelect
              {...register("peripheralSettings.ctClamp.current")}
              label="Rating (A)"
            >
              {currentOpts.map((opt) => (
                <option value={opt}>{opt}</option>
              ))}
            </FormSelect>

            <FormSelect
              {...register("peripheralSettings.ctClamp.resolution")}
              label="Resolution (mV)"
            >
              {resolutionOpts.map((opt) => (
                <option value={opt}>{opt}</option>
              ))}
            </FormSelect>
          </>
        }
      />
    </div>
  );
}
